import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AboutComponent} from './about/about.component';
import {EventsComponent} from './events/events.component';
import {WorkComponent} from './work/work.component';
import {ContactComponent} from './contact/contact.component';
import {GalleryModule} from '../gallery/gallery.module';

@NgModule({
    declarations: [
        EventsComponent,
        WorkComponent,
        AboutComponent,
        ContactComponent
    ],
    exports: [
        EventsComponent,
        WorkComponent,
        AboutComponent,
        ContactComponent
    ],
    imports: [
        CommonModule,
        GalleryModule
    ],
    providers: [],
    bootstrap: []
})
export class ChaptersModule {
}
