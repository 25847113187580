import {Component} from '@angular/core';
import {EventsChapter} from '../../../lib/types/chapter.types';
import {AppService} from '../../../lib/services/app.service';

@Component({
    selector: 'events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent {
    get events(): EventsChapter {
        return this.appService.chapters.events;
    }

    public get hasDescription(): boolean {
        return this.events.description.length > 0;
    }

    public get hasEvents(): boolean {
        return this.events.events.length > 0;
    }

    constructor(private appService: AppService) {
    }
}
