import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {AppService} from '../../../lib/services/app.service';
import {GalleryItem} from '../../../lib/types/gallery.types';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from '../../gallery/swiper/swiper.component';
import {WorkChapter} from '../../../lib/types/chapter.types';
import {GalleryService} from '../../../lib/services/gallery.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'work',
    templateUrl: './work.component.html',
    styleUrls: ['./work.component.scss']
})
export class WorkComponent implements AfterViewInit, OnDestroy {
    @ViewChild('swiper') swiper: SwiperComponent;

    public swiperOptions: SwiperOptions = {
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: this.slidesPerView,
        slidesPerGroup: 1,
        spaceBetween: 10,
        preventClicks: true,
        noSwiping: true,
        allowTouchMove: false,
        zoom: false
    };

    get work(): WorkChapter {
        return this.appService.chapters.work;
    }

    get hasDescription(): boolean {
        return this.work.description.length > 0;
    }

    private get slidesPerView(): number {
        if (window.innerWidth > 768) {
            return 4;
        } else if (window.innerWidth > 385) {
            return 2;
        } else {
            return 1;
        }
    }

    private windowSizeChangedSubscription: Subscription;

    constructor(public appService: AppService,
                private galleryService: GalleryService) {
        this.work.preview.forEach((item: GalleryItem) => {
            item.path = this.galleryService.getPathToImageThumbnail(item.name);
        });
        this.windowSizeChangedSubscription = this.appService.windowSizeChanged.subscribe(() => {
            if (this.work.carousel) {
                this.swiperOptions.slidesPerView = this.slidesPerView;
                this.swiper.update(this.swiperOptions);
            }
        });
    }

    ngAfterViewInit() {
        if (this.work.carousel) {
            this.swiper.update();
        }
    }

    ngOnDestroy() {
        this.windowSizeChangedSubscription.unsubscribe();
        if (this.work.carousel) {
            this.swiper.stopAutoplay();
        }
    }
}
