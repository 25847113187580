import {Component} from '@angular/core';
import {ContactChapter} from '../../../lib/types/chapter.types';
import {AppService} from '../../../lib/services/app.service';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    get contact(): ContactChapter {
        return this.appService.chapters.contact;
    }

    get eMailhRef(): string {
        return 'mailto:' + this.contact.eMail;
    }

    get phonehRef(): string {
        let tel: string = this.contact.phone.trim().replace(/\s/g, '');
        if (tel.length <= 10 && tel.charAt(0) === '0') {
            tel = '0041' + tel.substring(1);
        }
        return 'tel:' + tel;
    }

  constructor(private appService: AppService) { }

}
