import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {AppService} from '../../lib/services/app.service';
import {FilterItem, GalleryItem} from '../../lib/types/gallery.types';
import {SwiperComponent} from './swiper/swiper.component';
import {GridComponent} from './grid/grid.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements AfterViewInit {
    @ViewChild('swiper') swiper: SwiperComponent;
    @ViewChild('grid') grid: GridComponent;

    public isGridViewActive: boolean = true;
    public isFilterMenuOpen: boolean = false;

    public visibleImages: GalleryItem[] = [];
    public filters: Map<string, FilterItem> = new Map<string, FilterItem>();

    private gridSubscription: Subscription;
    private allFilterName: string = 'Alle';

    constructor(public appService: AppService) {
        this.appService.windowSizeChanged.subscribe(() => {
            this.setGalleryHeight();
        });

        this.filters.set(this.allFilterName, {name: this.allFilterName, active: true});
        for (const filter of appService.filters) {
            this.filters.set(filter, {name: filter, active: false});
        }
        this.visibleImages = this.appService.galleryItems;
    }

    ngAfterViewInit() {
        this.setGalleryHeight();
        this.refreshGridSubscription();
    }

    get filterValues(): FilterItem[] {
        return Array.from(this.filters.values());
    }

    public showSlider(show: boolean, activeImage: GalleryItem = this.visibleImages[0]) {
        this.isGridViewActive = !show;
        if (show) {
            setTimeout(() => {
                this.swiper.update({initialSlide: this.visibleImages.indexOf(activeImage)});
            });
        } else {
            this.refreshGridSubscription();
        }
        this.setGalleryHeight();
    }

    public toggleFilterMenuOpen() {
        this.isFilterMenuOpen = !this.isFilterMenuOpen;
        this.setGalleryHeight();
    }

    public toggleFilter(filter: FilterItem) {
        if (filter.name === this.allFilterName) {
            this.filters.get(filter.name).active = true;
            this.filters.forEach((value: FilterItem, name: string) => {
                if (name !== this.allFilterName) {
                    this.filters.get(name).active = false;
                }
            });
            this.visibleImages = this.appService.galleryItems;
        } else {
            this.filters.get(filter.name).active = !this.filters.get(filter.name).active;
            const activeFilters: string[] = [];
            this.filters.forEach((value: FilterItem, name: string) => {
                if (name !== this.allFilterName) {
                    if (this.filters.get(name).active) {
                        activeFilters.push(name);
                    }
                }
            });
            this.filters.get(this.allFilterName).active = activeFilters.length === 0;

            if (activeFilters.length === 0) {
                this.visibleImages = this.appService.galleryItems;
            } else {
                this.visibleImages = this.appService.galleryItems.filter((img: GalleryItem) => {
                    const activeFilter: string[] = img.filters.filter((filter: string) => {
                        return activeFilters.includes(filter);
                    });
                    return activeFilter.length === activeFilters.length;
                });
            }
        }
        this.showSlider(!this.isGridViewActive);
    }

    private setGalleryHeight() {
        setTimeout(() => {
            if (document.getElementById('head')) {
                const headHeight: number = document.getElementById('head').offsetHeight;
                document.getElementById('gallery')
                    .setAttribute('style', 'margin-top: ' + headHeight + 'px;');
                if (document.getElementById('swiperContainer')) {
                    document.getElementById('swiperContainer')
                        .setAttribute('style', 'height: ' + (window.innerHeight - headHeight) + 'px');
                }
            }
        });
    }

    private refreshGridSubscription() {
        setTimeout(() => {
            this.gridSubscription = this.grid.imageClicked.subscribe((item: GalleryItem) => {
                this.showSlider(true, item);
            });
        });
    }
}
