import {Component, Injector, Input, OnInit} from '@angular/core';
import {GalleryItem} from '../../../lib/types/gallery.types';
import {GalleryService} from '../../../lib/services/gallery.service';
import {Subject} from 'rxjs';
import {AppService} from '../../../lib/services/app.service';

@Component({
    selector: 'grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
    @Input() galleryItems: GalleryItem[];
    @Input() showOverlay: boolean = true;

    public imageClicked: Subject<GalleryItem> = new Subject<GalleryItem>();
    public columnClass: string;

    private margins: Map<number, string> = new Map<number, string>();
    private appService: AppService;
    private galleryService: GalleryService;

    constructor( injector: Injector) {
        this.margins.set(386, 'twoCols');
        this.margins.set(769, 'threeCols');
        this.margins.set(986, 'fourCols');
        this.margins.set(0, 'fiveCols');

        this.setImageWidth();

        this.appService = injector.get(AppService);
        this.galleryService = injector.get(GalleryService);
        this.appService.windowSizeChanged.subscribe(() => {
            this.setImageWidth();
        });
    }

    ngOnInit() {
        this.galleryItems.forEach((item: GalleryItem) => {
            item.path = this.galleryService.getPathToImageThumbnail(item.name);
        });
    }

    openImage(item: GalleryItem) {
        this.imageClicked.next(item);
    }

    private setImageWidth() {
        this.columnClass = '';
        for (const margin of Array.from(this.margins.keys())) {
            if (this.columnClass.length < 1) {
                if (window.innerWidth < margin) {
                    this.columnClass = this.margins.get(margin);
                } else if (margin === 0) {
                    this.columnClass = this.margins.get(0);
                }
            }
        }
    }
}
