import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Chapter, Chapters} from '../types/chapter.types';
import {GalleryItem} from '../types/gallery.types';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public isLoaded: boolean = false;

    public chapters: Chapters;
    public chapterLinks: {name: string, title: string}[] = [];
    public galleryTitle: string;
    public galleryItems: GalleryItem[] = [];
    public filters: string[] = [];
    public lastModified: number;

    public windowWidth: number;
    public isMenuOpen: boolean;
    public isGalleryOpen: boolean = false;

    public windowSizeChanged: Subject<{ width: number, height: number }> = new Subject<{ width: number, height: number }>();

    public get isLandscape(): boolean {
        return this.windowWidth > 768;
    }

    private jsonPath = '../../assets/content/';

    constructor(private httpService: HttpClient) {
        this.windowWidth = window.innerWidth;
        window.onresize = () => {
            this.windowWidth = window.innerWidth;
            this.windowSizeChanged.next({width: window.innerWidth, height: window.innerHeight});
        };

        this.loadChapters();
        this.loadGallery();
    }

    public toggleMenuOpen() {
        this.isMenuOpen = !this.isMenuOpen;
    }

    public toggleGalleryOpen() {
        this.isGalleryOpen = !this.isGalleryOpen;
    }

    private loadChapters() {
        this.loadJson(this.httpService, 'chapters').subscribe((data: { chapters: Chapters, galleryTitle: string, lastModified: number }) => {
            this.lastModified = data.lastModified;
            this.galleryTitle = data.galleryTitle;
            this.chapters = data.chapters;
            for(const key of Object.keys(this.chapters)) {
                if (this.chapters.hasOwnProperty(key)) {
                    const chapter: Chapter = this.chapters[key];
                    if (chapter.isVisible) {
                        this.chapterLinks.push({name: key, title: chapter.title});
                    }
                }
            }
            this.isLoaded = true;
        });
    }

    private loadGallery() {
        this.loadJson(this.httpService, 'gallery').subscribe((data: { images: GalleryItem[], filters: string[] }) => {
            this.galleryItems = data.images;
            this.galleryItems.forEach((item: GalleryItem) => {
                if (!item.filters) {
                    item.filters = [];
                }
            });
            this.filters = data.filters;
        });
    }

    private loadJson(httpService: HttpClient, fileName: string): Observable<any> {
        return httpService.get(this.jsonPath + fileName + '.json');
    }
}
