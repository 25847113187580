import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GalleryService {
    private thumbnailPath: string = '../../../../../assets/img/gallery/thumbnails/';
    private fullPath: string = '../../../../../assets/img/gallery/large/';
    private generalPath: string = '../../../../../assets/img/general/';

    getPathToImageThumbnail(imgName: string): string {
        return this.thumbnailPath + imgName;
    }

    getPathToFullImage(imgName: string): string {
        return this.fullPath + imgName;
    }

    getPathToGeneralImage(imgName: string): string {
        return this.generalPath + imgName;
    }
}