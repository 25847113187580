import {Component, Injector, Input} from '@angular/core';
import {GalleryItem} from '../../../lib/types/gallery.types';
import {GalleryService} from '../../../lib/services/gallery.service';

@Component({
    selector: 'image-overlay',
    templateUrl: './image-overlay.component.html',
    styleUrls: ['./image-overlay.component.scss']
})
export class ImageOverlayComponent {
    @Input() item: GalleryItem;
    @Input() fullSize: boolean = false;

    private galleryService: GalleryService;

    constructor(private injector: Injector) {
        this.galleryService = injector.get(GalleryService);
    }
}
