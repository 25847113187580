import {Component} from '@angular/core';
import {AppService} from '../../lib/services/app.service';

@Component({
    selector: 'menu-content',
    templateUrl: './menu-content.component.html',
    styleUrls: ['./menu-content.component.scss']
})
export class MenuContent {

    constructor(public appService: AppService) {
    }

    openGallery() {
        this.appService.toggleGalleryOpen();
        this.appService.toggleMenuOpen();
    }

}
