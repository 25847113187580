import {NgModule} from '@angular/core';
import {GalleryComponent} from './gallery.component';
import {CommonModule} from '@angular/common';
import {GridComponent} from './grid/grid.component';
import {SwiperComponent} from './swiper/swiper.component';
import {ImageOverlayComponent} from './image-overlay/image-overlay.component';

@NgModule({
    declarations: [
        GalleryComponent,
        GridComponent,
        SwiperComponent,
        ImageOverlayComponent
    ],
    exports: [
        GalleryComponent,
        GridComponent,
        SwiperComponent,
        ImageOverlayComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [],
    bootstrap: []
})
export class GalleryModule {
}
