import {Component} from '@angular/core';
import {AboutChapter} from '../../../lib/types/chapter.types';
import {AppService} from '../../../lib/services/app.service';
import {GalleryService} from '../../../lib/services/gallery.service';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
    get about(): AboutChapter {
        return this.appService.chapters.about;
    }

    constructor(private appService: AppService,
                private galleryService: GalleryService) {
        this.about.image.path = this.galleryService.getPathToGeneralImage(this.about.image.name)
    }

}
