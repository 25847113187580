import {Component, Injector, Input, OnInit} from '@angular/core';
import Swiper, {SwiperOptions} from 'swiper';
import {GalleryItem} from '../../../lib/types/gallery.types';
import {GalleryService} from '../../../lib/services/gallery.service';

@Component({
    selector: 'swiper',
    templateUrl: './swiper.component.html',
    styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent implements OnInit {
    @Input() options: SwiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            dynamicBullets: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
        loop: true,
        zoom: false
    };
    @Input() galleryItems: GalleryItem[];
    @Input() thumb: boolean = false;
    @Input() showOverlay: boolean = true;
    @Input() fullScreen: boolean = true;

    private swiper: Swiper;

    private galleryService: GalleryService;

    constructor(private injector: Injector) {
        this.galleryService = injector.get(GalleryService);
    }

    ngOnInit() {
        this.galleryItems.forEach((item: GalleryItem) => {
            if (this.thumb) {
                item.path = this.galleryService.getPathToImageThumbnail(item.name);
            } else {
                item.path = this.galleryService.getPathToFullImage(item.name);
            }
        });
    }

    update(newOptions?: SwiperOptions) {
        this.stopAutoplay();
        if (newOptions) {
            for (const key in newOptions) {
                if (newOptions.hasOwnProperty(key)) {
                    this.options[key] = newOptions[key];
                }
            }
        }
        setTimeout(() => {
            this.swiper = new Swiper('.swiper-container', this.options);
            this.swiper.update();
        });
    }

    startAutoplay() {
        if (this.options.autoplay) {
            this.swiper.autoplay.start();
        }
    }

    stopAutoplay() {
        if (this.options && this.options.autoplay && this.swiper) {
            this.swiper.autoplay.stop();
        }
    }
}
